import React, {useState, useEffect} from "react";

import BoralaaLayoutBR from "../../components/boralaa/layoutBR.js";
import SEO from "../../components/SEO.js";

function BoralaaBr (props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <>
            <SEO title="Boralaa" description={`Boralaa é um Boss Rush de turno com elementos de Souls-like. Você pode jogar sozinho, com seus amigos ou sua comunidade.`} />
            <BoralaaLayoutBR 
                valorTotal={255}
                totalApoiadores={3}
                tier1={0}
                tier2={1}
                tier3={0}
                tier4={2}
                tier5={0}
                tier6={0}
            />
        </>
    );
}

export default BoralaaBr;