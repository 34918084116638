import React, { useState, useEffect, useRef } from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import Header from "./header.js";
import CharsImage from "./chars-image.js";
import BtnMostrarMais from "./btn-mostrar-mais.js";
import Footer from "../footer.js";

import azhurImg from "../../../static/images/azhur_art_background.png";
import agnisImg from "../../../static/images/agnis_art_background.png";
import hibikiImg from "../../../static/images/hibiki_art_background.png";
import ericaImg from "../../../static/images/erica_art_background.png";
import kirishimaImg from "../../../static/images/kirishima_art_background.png";

import agnisIdleImg from "../../../static/images/agnis_idle.webp";
import ericaIdleImg from "../../../static/images/erica_idle.webp";
import hibikiIdleImg from "../../../static/images/hibiki_idle.webp";
import kirishimaIdleImg from "../../../static/images/kirishima_idle.webp";


function BoralaaLayoutBR (props) {
    const [visibilidadeBotaoApoiar, setVisibilidadeBotaoApoiar] = useState(false);
    const divInicialRef = useRef(null);
    const divApoioRef = useRef(null);
    const resetScrollElement = useRef(null);
    
    useEffect(() => {        
        const observador = new IntersectionObserver((entries) => {setVisibilidadeBotaoApoiar(!entries[0].isIntersecting)}, {threshold: 0.1});
        observador.observe(divInicialRef.current);
        observador.observe(divApoioRef.current);
    }, []);

    const resetarScroll = () => {
        return resetScrollElement.current.scrollTop = 0;
    };

    return (
        <>
            <Header />
            <main>
                <div ref={divInicialRef} className="background-escuro">
                    <div className="container">
                        <h1 className="pb-4 titulo">Projeto Boralaa</h1>
                        <div className="row">
                            <div className="col-12">
                                <div style={{textAlign: "center"}}>
                                    <p className="subtitulo">Um Boss Rush de turno com elementos de Souls-like.</p>
                                    <p className="subtitulo">Jogue sozinho, com e contra seus amigos ou sua comunidade.</p>
                                </div>
                            </div>

                            <div className="col"></div>
                            <div className="col-12 col-lg-5">
                                <div className="m-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column" style={{textAlign: "left"}}>
                                            <span className="numeros-especiais">R$ {props.valorTotal}</span>
                                            <small>Total recebido</small>
                                        </div>

                                        <div className="d-flex flex-column" style={{textAlign: "right"}}>
                                            <span className="numeros-especiais">{props.totalApoiadores}</span>
                                            <small>Apoiadores</small>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <a className="mt-4 btn btn-block btn-danger btn-lg force-link-white" href="#apoiar">Apoiar</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>

                    </div>
                </div>

                <div className="container background-claro">
                    <h1 className="pb-4 titulo">O que é Boralaa?</h1>

                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={azhurImg} nome={"Azhur, a Primeira"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <p>
                                    Em um fatídico dia, uma voz foi ouvida por todos. Não importava o lugar, raça ou idade. 
                                    Todos em Boralaa escutaram em sincronia a verdade que mudou o futuro daquele povo.
                                </p>
                                <span className="citacao">"Urrem, adaptem, amaldiçoem e bendirão."</span>
                                <span className="citacao">"Superem à abominação."</span>
                                <span className="mb-3 citacao">"Dor é Provação."</span>
                                <p>
                                    E assim uma luz começou a brilhar. Uma indicação nos céus informando o lugar. 
                                    O local onde tudo aconteceria, com a profecia encravada sob pedra. 
                                    Um contador exibia o número 365: o tempo que existia até a primeira calamidade se apresentar.
                                </p>
                                <p>
                                    Para desbravar os mistérios de Boralaa, é preciso passar pelos <strong>Chefões</strong>.
                                    Mas a vida de herói não é fácil, pois você tem que descobrir como derrotá-los
                                    conforme os turnos passam.
                                </p>
                                <p>
                                    <strong>Sem ajuda, apenas com sua atenção e estratégia!</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="background-escuro">
                    <div className="container">
                        <h1 className="pb-4 titulo">Boralaa testar!</h1>
                        <div className="m-2 row align-items-center">
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2">
                                <CharsImage src={agnisImg} nome={"Agnis, a Berserker Sorridente"} />
                            </div>
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1">
                                <div className="d-flex flex-column">
                                    <p>
                                        Bom, nada melhor do que <strong>testar o game</strong> para entender como funciona!
                                        Nesta pequena <strong>demo</strong>, você enfrentará o Chefão inicial do game e irá conhecer melhor nossa proposta.
                                    </p>
                                    <p>
                                        Você irá reparar que o game ainda <strong>precisa de alguns polimentos</strong>, principalmente na parte de efeitos visuais e áudio,
                                        mas falaremos mais abaixo de como pretendemos fazer esses ajustes.
                                    </p>
                                    <p>
                                        E apesar da demo ter apenas o modo single player, futuramente você poderá jogar tanto <strong>com e contra</strong> seus amigos.
                                        Ou caso você seja streamer, fazer o mesmo com e contra <strong>sua comunidade ao vivo</strong>.
                                    </p>
                                    <p>
                                        A demo está disponível na <a href="https://katdevs.itch.io/boralaa" target="_blank" rel="noopener noreferrer">Itch.io </a>
                                        e <a href="https://store.steampowered.com/app/2007460/Boralaa/" target="_blank" rel="noopener noreferrer">Steam</a>.
                                        O game atualmente está disponível para <strong>Windows</strong> nas linguagens <strong>Português e Inglês</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container background-claro">
                    <h1 className="pb-4 titulo">Focado na Lore</h1>

                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={hibikiImg} nome={"Hibiki, a Doida das Poções"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <p>
                                    Boralaa foi criado com a ideia de <strong>lore first</strong>: a história foi criada primeiro e o jogo foi desenvolvido com base nela.
                                </p>
                                <p>
                                    Acreditamos que uma história rica é uma fonte gigantesca de entretenimento, tanto dentro quanto fora do game. 
                                    E queremos criar uma <strong>experiência tão boa</strong> quanto outros jogos e empresas trouxeram para nós.
                                </p>
                                <p>
                                    Abaixo você pode ler conhecer melhor o que já temos preparado para o <strong>universo de Boralaa</strong>!
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr className="m-4"></hr>

                    <div className="m-2 row">
                        <div className="mb-4 col-12 col-sm-4">
                            <div className="list-group" id="list-tab" role="tablist" aria-hidden="true" onClick={resetarScroll}>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action active"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-agnis"
                                    data-bs-toggle="list"
                                    href="#list-agnis"
                                    role="tab"
                                    aria-controls="list-agnis">
                                    <span>Agnis</span>
                                    <img src={agnisIdleImg} className="img-fluid" alt="Agnis Pixel Art"></img>
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-erica"
                                    data-bs-toggle="list"
                                    href="#list-erica"
                                    role="tab"
                                    aria-controls="list-erica">
                                    <span>Erica</span>
                                    <img src={ericaIdleImg} className="img-fluid" alt="Erica Pixel Art"></img>                                    
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-hibiki"
                                    data-bs-toggle="list"
                                    href="#list-hibiki"
                                    role="tab"
                                    aria-controls="list-hibiki">
                                    <span>Hibiki</span>
                                    <img src={hibikiIdleImg} className="img-fluid" alt="Hibiki Pixel Art"></img>                                    
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-kirishima"
                                    data-bs-toggle="list"
                                    href="#list-kirishima"
                                    role="tab"
                                    aria-controls="list-kirishima">
                                    <span>Kirishima</span>
                                    <img src={kirishimaIdleImg} className="pe-2 img-fluid" alt="Kirishima Pixel Art"></img>                                    
                                </div>
                            </div>
                        </div>
                        
                        <div ref={resetScrollElement} style={{height: "320px"}} className="col-12 col-sm-8 overflow-auto">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="list-agnis" role="tabpanel" aria-labelledby="list-hero-agnis">
                                    <p>
                                        Filha dos Sigmond, renomados caçadores em Sol de Inverno, Agnis veio de uma união improvável entre um pai metódico e rígido com uma mãe 
                                        extrovertida e otimista que se davam surpreendentemente bem. Junto de seu irmão, viviam felizes em meio as caçadas rotineiras em família.
                                    </p>
                                    <p>
                                        Certo dia, um emissário do governo bateu a porta. Ao ver sua insignia, Agnis logo percebeu o que estava por vir: uma missão de alto nível.
                                        Se travava de uma aberração desconhecida que estava assolando uma das fronteiras da cidade e eles estavam sendo convocados para sua caçada.
                                        Diferente das outras missões, os dois irmãos desta vez não poderiam participar. No dia seguinte, Agnis recebeu um beijo na testa de sua mãe
                                        como de costume antes de seus pais partirem para mais um dia de trabalho. Ela apenas não imaginava que aquele beijo era o último que receberia.
                                    </p>
                                    <p>
                                        Dias depois, a besta foi derrotada. A comemoração foi absorvida pelo luto, em uma das batalhas com mais baixas de caçadores já registrada.
                                        Entre eles, a mãe de Agnis. Gravemente ferido e sem sua esposa, a recuperação de seu pai foi árdua. Amargurado com os acontecimentos e sua própria falha,
                                        caiu em profunda depressão.
                                    </p>
                                    <p>
                                        Sobrevivendo a todo aquele momento infeliz, Agnis possuia apenas memórias como conforto para a dor que estava sofrendo. A única coisa que aliviava
                                        todo o desespero em seu coração era a lembrança do belo sorriso de sua mãe. Aos poucos, passou a imita-la. Ela queria que todos conhecessem o sorriso
                                        que a fazia sentir tanta saudade.
                                    </p>
                                    <p>
                                        Com treinamento duro e força de vontade, ultrapassava seus limites físicos em busca de também se tornar uma caçadora. Na Academia Nacional, recebeu o
                                        apelido de Berserker Sorridente devido ao seu estilo de luta agressivo e com um sorriso no rosto, independente da situação. Durante sua participação
                                        na Alvorada do Inverno, existia uma grande expectativa no ar já que a filha dos Sigmond participaria. Durante o evento, suas habilidades e sorriso foram
                                        reconhecidos, além de ter sido escolhida para elite da família Tiwaz, a mesma de seus pais.
                                    </p>
                                    <p>
                                        Conforme abatia monstros e completava missões, seu renome como caçadora e suas cicatrizes cresciam.
                                        Mas até os dias de hoje, existe um rumor. Rumor esse cujo a Berserker Sorridente não possui uma marca sequer em suas costas.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-erica" role="tabpanel" aria-labelledby="list-hero-erica">
                                    <p>
                                        Erica sempre foi uma criança calma. Sendo a irmã mais velha, sempre foi responsável por seus 2 irmãos gêmeos enquanto seus pais não estavam em casa.
                                        Seguindo a tradição, seus pais há mandaram para a Academia Nacional aos 10 anos.
                                    </p>
                                    <p>
                                        Na academia, era muito popular devido sua gentileza e altruísmo, lidando bem com as pessoas e mediando as brigas que ocasionalmente aconteciam.
                                        Apesar de sempre bem avaliada em todas as matérias, era considerada um prodígio na manipulação de Astra e no controle do arco e flecha. Ao empunha-lo,
                                        algo mudava. Um pequeno sorriso surgia em seu rosto conforme toda sua calma se tornava foco, garantindo os acertos mais impressionantes e recebendo
                                        elogios de seus instrutores.
                                    </p>
                                    <p>
                                        Entretanto, foi durante a Alvorada do Inverno onde Erica ganhou sua fama. Usando encantamentos complexos para proteger e curar seus aliados, criando
                                        flechas de puro Astra e as disparando com precisão incrível, obteve o título de Foco Absoluto ao final do evento. Erica recebeu o convite oficial de
                                        todas as matriarcas, mas decidiu pela família Gebo, a mesma família que seus pais fazem parte.
                                    </p>
                                    <p>
                                        Seguindo os ideais ensinados por sua nova casa, Erica aprendeu o valor do equilíbrio. Ser generosa com os seus, mas implacável de frente ao inimigo.
                                        Como recompensa por suas conquistas e habilidades, aos 16 anos recebeu seu Techno Arco: um arco tecnológico encantado, capaz de receber cargas de Astra
                                        e potencializar as aptidões de seu usuário. Atualmente lidera parte das forças de defesa de Sol de Inverno.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-hibiki" role="tabpanel" aria-labelledby="list-hero-hibiki">
                                    <p>
                                        Nascida em família de origem humilde, donos de um quiosque na costa de Utsuichi, Hibiki ajudava seus pais desde muito nova. Aprendeu a cozinhar com seu pai,
                                        mas o calor do fogão guardava um outro gosto: a alquimia. Tudo graças a um livro que foi esquecido por lá. Enquanto o dono não era encontrado, Hibiki ficava
                                        maravilhada com o mundo novo que estava na palma de suas mãos. Mesmo usando do improviso e o pouco recurso que tinha, Hibiki conseguiu dar seus primeiros
                                        passos sozinha dentro daquela misteriosa ciência.
                                    </p>
                                    <p>
                                        Dias depois, em busca de seu livro perdido, a alquimista ouviu sobre os feitos da menina. Impressionada, prometeu auxiliá-la com seus estudos, trazendo
                                        conhecimento e recursos sempre que passasse pela região. Mas caberia a Hibiki se esforçar e aprender.
                                    </p>
                                    <p>
                                        Conforme os anos passaram, sua curiosidade e esforço resultaram numa evolução notável. Não apenas na alquimia comum, mas com manipulações inovadoras e
                                        efeitos surpreendentes. Como forma de reconhecimento, sua mentora passou a recomenda-la para alguns de seus clientes. Devido a grande qualidade de seus
                                        produtos, Hibiki passou a receber mais e mais demanda. Então decidiu montar uma pequena loja, ao lado de seus pais.
                                    </p>
                                    <p>
                                        Em pouco tempo, o Beco da Gata ganhou fama por toda Boralaa, tanto por seus itens quanto pelas peculiaridades de sua dona. Por não conseguir segurar seu vício,
                                        virar noites lendo e praticando virou um costume. Então é comum encontrar Hibiki tirando cochilos em cantos aleatórios da loja. E além de usar apenas frascos
                                        quadrados para suas poções, é normal vê-la de ponta-cabeça nos lugares mais inusitados pensando em soluções para seus problemas.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-kirishima" role="tabpanel" aria-labelledby="list-hero-kirishima">
                                    <p>
                                        Kirishima nasceu na tribo Miyazaki, responsável pela região sudeste de Utsuichi. Devido a um incidente misterioso que resultou na perda de seus pais, passou a
                                        ser criado desde muito novo pelo Clã Kuwahara, um dos mais radicais da região. Moldado pelos ideias de violência, caos e liberdade, Kirishima se tornou a
                                        ferramenta perfeita para o plano perfeito: acabar com a Federação de Utsuichi e todos os humanos que nela vivem.
                                    </p>
                                    <p>
                                        Seguindo as ordens de seus líderes, seu caminho era traçado por assaltos e execuções. Mesmo sendo jovem, rastros de destruição e sangue eram deixados por onde passava.
                                        E fazia tudo com muito prazer, acreditando verdadeiramente nos ideais que lhe foram ensinados.
                                    </p>
                                    <p>
                                        Certa missão, um grupo foi encarregado de invadir uma instituição e acabar com tudo que havia dentro. Kirishima recebeu a função de incendiar o lugar, e assim o fez.
                                        Com sua tarefa concluída, foi ao reencontro dos outros integrantes. Conforme se aproximava, o calor das chamas misturava-se com choro e cheiro de sangue. Caminhando,
                                        encontrou por diversos corpos de crianças humanas e semi-humanas, brutalmente assassinadas. Confuso, correu em busca de respostas.
                                    </p>
                                    <p>
                                        Ao encontrá-los, estavam entretidos com um grupo de jovens que agonizavam, pedindo por socorro. Fazendo-os parar, questionou o que estava acontecendo e aonde estavam.
                                        "Claro que é uma escola. Aonde mais poderíamos encontrar tantos merdinhas com sangue humano de uma vez?" respondeu um deles. "Temos que acabar com mal pela raiz."
                                        disse outro. Kirishima não entendia o motivo, mas presenciar tal situação, tanto o estado daquelas crianças em sofrimento e medo, gritando pelas suas vidas,
                                        quanto o prazer de seus colegas, fez-o arder em ódio! Como alguém como ele poderia se sentir assim, sendo o executor de tantos inocentes. Por que ver aquelas
                                        crianças em tal estado teria abalado ele? Perguntas as quais ele não teve tempo de responder.
                                    </p>
                                    <p>
                                        Liberando toda sua raiva, como um urso protegendo seus filhotes, combateu os agressores. Não importava os ataques ou feridas que recebia, derrubou um após o outro.
                                        Antes da construção desabar, retirou todos que foi capaz. Com a chegada dos Garras de Aço, clã responsável em lidar com as ações dos Kuwahara, a situação foi controlada.
                                        Todos estavam incrédulos com o que havia acontecido, pois o infame Kirishima havia salvado 52 crianças sozinho. E em troca, teve 50% do corpo carbonizado. Levado como
                                        prisioneiro pelos Garras de Aço, sobreviveu graças ao tratamento recebido pelos curandeiros do clã. Contudo, as sequelas permaneceram. Até ficar de pé sozinho se tornou
                                        uma tarefa difícil.
                                    </p>
                                    <p>
                                        Durante os anos que ficou sob custódia, foi visitado constantemente pelos jovens salvos por ele, com presentes ou agradecimentos. De forma icônica, em todos eles haviam
                                        alguma menção ao lema dos Garras de Aço: "Honrando o passado, protegendo o futuro". Mesmo não sabendo como lidar com a situação, Kirishima passou a sentir-se estranhamente
                                        confortável com as ocasiões. Conforme o tempo passava, ele entendia melhor o sentimento daquele fatídico dia. Tudo que havia aprendido como realidade absoluta, não passava
                                        de um ponto de vista corrompido pelo ódio.
                                    </p>
                                    <p>
                                        Por volta dos seus 27 anos, quase uma década depois do acontecido, Kirishima tomou uma decisão. Para expiar seus pecados, faria o que fosse possível para ajudar os
                                        Garras de Aço. Ele continuaria protegendo as pessoas da mesma forma que protegeu naquele dia. A partir de uma audiência pública com grande parte do clã presente,
                                        seu pedido foi aceito. Não apenas se tornou um membro oficial, mais foi incluído no programa REZ: programa este em parceria com a República de Tecnos para reabilitação
                                        física através da tecnologia e magia.
                                    </p>
                                    <p>
                                        Esse foi o primeiro passo na grande reviravolta de Kirishima. Quem diria que 15 anos depois, ele se tornaria sucessor e chefe dos Garras de Aço.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="background-escuro">
                    <h1 className="pb-4 titulo">Nossos Planos</h1>

                    <div className="container">
                        <div className="m-2 row">
                            <div className="col-12">
                                <p>
                                    Toda a produção até o momento foi feita com pouco recurso, sendo financiada do <strong>próprio bolso</strong>.
                                    Apesar de ser nosso primeiro game, ganhamos muita experiência produzindo essa demo e agora
                                    estamos <strong>buscando recursos</strong> para continuar com o projeto.
                                </p>
                                <p>
                                    No estado atual, o game está precisando de melhorias em: <strong>VFX</strong>, <strong>SVX e dublagem</strong>, <strong>ambientação</strong>,
                                    além da <strong>adição dos Chefões e Heróis</strong> restantes.

                                    Para isso, criamos <strong>metas</strong> para ajudar no gerenciamento do projeto. Conforme forem batidas, 
                                    iremos utilizar os recursos no jogo e iniciar a produção referente.
                                </p>
                                <p>
                                    Um de nossos planos é ter um desenvolvimento <strong>transparente</strong>. Todas nossas decisões e atualizações serão informadas através de
                                    nosso <a href="https://discord.gg/x3qq87QS2P" target="_blank" rel="noopener noreferrer">Discord</a> e <a href="https://twitter.com/KatDevs" target="_blank" rel="noopener noreferrer">Twitter</a>. 
                                    Acompanhe a gente para ficar por dentro das novidades do Boralaa, além de poder deixar seu feedback.
                                </p>
                            </div>
                        </div>

                        <div className="m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">

                            <div className="col">
                                <div className="card h-100 text-white bg-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>1° meta</span>
                                        <span>Em andamento</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 10.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            O objetivo é ajustar os detalhes que faltam no primeiro estágio (que está na demo).
                                        </p>
                                        <span>
                                            Ainda é preciso adicionar efeitos visuais para os ataques, adicionar aúdio e 
                                            melhorar a qualidade da ambientação.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>VFX</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>SFX and Dub</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>Ambientação</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>Mão de Obra</strong>: R$ 3.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 1.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>2° meta</span>
                                        <span>Aguardando</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 10.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            A história de Boralaa é parte essencial para dar sequência e objetivo ao jogo.
                                        </p>
                                        <span>
                                            Queremos expandir o universo e assim ser possível conta-la tanto dentro quanto fora do jogo.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Desenvolvimento da Lore</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>Concepts e Artes</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>Acervo Digital</strong>: R$ 2.000</span>
                                            <span className="span-card-collapse"><strong>Mão de Obra</strong>: R$ 3.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 1.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>3° meta</span>
                                        <span>Aguardando</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 40.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Desenvolvimento do segundo e terceiro estágio do game.  
                                        </p>
                                        <span>
                                            Os Chefões, Heróis, splash e pixel arts, efeitos e ambientação.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: R$ 6.000</span>
                                            <span className="span-card-collapse"><strong>Artes</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>SFX e dublagem</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>Ambientação</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>Mão de Obra</strong>: R$ 14.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 4.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="metas-linha-2" className="collapse m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">
                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>4° meta</span>
                                        <span>Aguardando</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 40.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Desenvolvimento do quarto e quinto estágio do game.  
                                        </p>
                                        <span>
                                            Os Chefões, Heróis, splash e pixel arts, efeitos e ambientação.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: R$ 6.000</span>
                                            <span className="span-card-collapse"><strong>Artes</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>SFX e dublagem</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>Ambientação</strong>: R$ 4.000</span>
                                            <span className="span-card-collapse"><strong>Mão de Obra</strong>: R$ 14.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 4.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-info bg-transparent text-info">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>Meta Extra</span>
                                        <span>Aguardando</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 27.500</strong>
                                        </h5>
                                        <p className="card-text">
                                            Cinematic / Animação do universo de Boralaa.
                                        </p>
                                        <span>
                                            Apesar de não ser essencial, ajudará na divulgação do game, além de ser muito <strong>BRABO</strong> de ter!
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Contrato com estúdio</strong>: R$ 20.000</span>
                                            <span className="span-card-collapse"><strong>Divulgação</strong>: R$ 5.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 2.500</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-info bg-transparent text-info">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>Meta Extra</span>
                                        <span>Aguardando</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 61.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Adicionar animações e efeitos para cada ataque para <strong>TODOS</strong> os Heróis.
                                        </p>
                                        <span>
                                            Até o momento cada herói possui um animação e efeito para todos seus 4 ataques.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: R$ 12.000</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: R$ 24.000</span>
                                            <span className="span-card-collapse"><strong>SFX e dublagem</strong>: R$ 10.000</span>
                                            <span className="span-card-collapse"><strong>Mão de Obra</strong>: R$ 10.000</span>
                                            <span className="span-card-collapse"><strong>Reserva de Segurança</strong>: R$ 5.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                            <BtnMostrarMais id={"#metas-linha-2"} textoPreClique={"Mostrar mais"} textoPosClique={"Mostrar menos"}></BtnMostrarMais>
                        </div>

                    </div>
                </div>

                <div className="container background-claro" id="apoiar">
                    <h1 className="pb-4 titulo">Boralaa apoiar!</h1>

                    <div className="m-2 row">
                        <div className="mt-2 mb-2 col-12">
                            <p>
                                Gostou do projeto e <strong>quer nos ajudar</strong> a criar essa belezinha? <strong>Boralaa! </strong>
                                Criamos alguns tiers para você poder escolher como quer nos ajudar.
                                Cada uma possui <strong>sua recompensa</strong> em agradecimento pelo apoio e não se esqueça:
                                <strong> toda ajuda é bem-vinda</strong>, independente do valor.
                                Aos poucos, vamos chegar lá! 
                            </p>
                            <p>
                                E pode ficar tranquilo em questão de <strong>segurança</strong>. Fechamos uma parceria com a <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>,
                                plataforma de pagamentos utilizada pelo mundo inteiro e por empresas como <strong>Google e Zoom</strong>.
                                E não deixe de informar seu <strong>email</strong> durante o pagamento para entrarmos em contato com você no futuro. 
                            </p>
                        </div>
                    </div>

                    <div ref={divApoioRef} className="m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Tmj</h1>
                                    <p className="card-text">
                                        Um muito obrigado do fundo do coração! Pode parecer pouco, mas todo mundo ajudando com um pouquinho, a gente consegue.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Um grande obrigado! :D</span>
                                        <span>- Wallpaper em HD</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 5+</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier1}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/9AQdSce8Q4o7dd6cMN"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Quero o Jogo</h1>
                                    <p className="card-text">
                                        Neste você já leva uma cópia digital do game para casa quando ele estiver pronto
                                        e for lançado na STEAM.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Um grande obrigado! :D</span>
                                        <span>- Wallpaper em HD</span>
                                        <span>- Cópia digital do Game (STEAM)</span>                                        
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 30</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier2}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/28o4hCggYbQz5KE4gi"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Dois por Um</h1>
                                    <p className="card-text">
                                        Tier ideal caso queira presentear algum de seus amigos com um descontinho legal.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Um grande obrigado! :D</span>
                                        <span>- Wallpaper em HD</span>
                                        <span>- Duas cópias digitais do Game (STEAM)</span>    
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 50</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier3}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/cN2cO8d4M7Ajdd6fZ1"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Padrim</h1>
                                    <p className="card-text">
                                        Este é o Tier para aqueles que gostaram do projeto e querem ajudar mais,
                                        além de receber alguns mimos.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Todos os anteriores</span>
                                        <span>- Acesso ao beta fechado</span>  
                                        <span>- Cargo especial no Discord</span>
                                        <span>- Nome nos créditos do game</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 100+</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier4}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/9AQaG02q8g6Pb4YdQU"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Chefinho</h1>
                                    <p className="card-text">
                                        Este é para quem quer participar do projeto ativamente, recebendo acesso
                                        antecipado as versões e podendo dar feedback diretamente pelo Discord.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Todos os anteriores</span>
                                        <span>- Acesso as versões de teste</span>
                                        <span>- Feedback direto com a gente</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 500+</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier5}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/5kAcO88Ow6wf0qk005"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Chefão</h1>
                                    <p className="card-text">
                                        Este tier é para aqueles que podem investir e querem ajudar bastante no projeto.
                                        E em troca, deixarão sua marca em Boralaa.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Recompensas</strong></span>
                                        <span>- Todos os anteriores</span>
                                        <span>- Nomeie 1 dos heróis sob nossa revisão. [LIMITADO]</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">R$ 2000+</span>
                                        <small>Valor</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier6}</span>
                                        <small>Apoiadores</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/4gwdSc9SAcUDc92eV0"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            Quero esse Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="background-escuro">
                    <h1 className="pb-4 titulo">FAQ</h1>

                    <div className="container">
                        <div className="m-2 row align-items-center">
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2">
                                <CharsImage src={ericaImg} nome={"Erica, o Foco Absoluto"} />
                            </div>
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1">
                                <div className="d-flex flex-column">
                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Por que não usar o Kickstarter?</h1>
                                    <p>
                                        O Kickstarter apenas aceita projetos de uma lista específica de paises.
                                        Infelizmente o Brasil <strong>não</strong> está nela. <i>Sadge</i>
                                    </p>

                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>De onde surgiu a ideia?</h1>
                                    <p>
                                        Durante uma live minha na Twitch, estava testando algumas formas de interação com minha comunidade e
                                        encontrei uma batalha onde os viewers junto comigo tinham que derrotar um Chefão. 
                                    </p>
                                    <p>
                                        Foi legal e então pensei: <strong>"Se fosse melhor produzido, seria mais divertido!"</strong>.
                                        E assim surgiu o Boralaa!
                                    </p>

                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Quem é a Kat Devs?</h1>
                                    <p>
                                        Somos uma pequena <strong>startup</strong> voltada para o mercado de games e eSports.
                                        Atualmente, eu <a href="https://linktr.ee/katreque" target="_blank" rel="noopener noreferrer">(Katreque)</a> sou
                                        o único membro trabalhando na divisão de games e o Boralaa é nosso <strong>1° projeto</strong> como estúdio.
                                    </p>
                                    <p>
                                        Acabo fazendo praticamente tudo no game, menos a parte artística que é comissionada.
                                        O famoso caso de estúdio pequeno que não tem grana para contratar mais gente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container background-claro">
                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={kirishimaImg} nome={"Kirishima, o Chefe dos Garras de Aço"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Quem é o Katreque?</h1>
                                <p>
                                    Salve! Sou o CEO da Kat Devs, amante de tecnologia e games, e desenvolvedor há mais de 5 anos.
                                    Estou noivo e juntando dinheiro <s>ou tentando</s> para comprar um lugarzinho para morar.
                                </p>
                                <p>
                                    <strong>Meu sonho</strong> é trabalhar com games no Brasil, mas é muito difícil conseguir investimento nessa área por aqui.
                                    Então acabo precisando trabalhar em outros projetos para sobreviver. 
                                </p>
                                <p>
                                    O <strong>objetivo</strong> é crescer como empresa para ter recursos suficientes para criar e finalizar nossos próprios projetos.
                                    Mas no momento preciso da <strong>sua ajuda</strong> para começar.
                                </p>

                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Quais são os riscos?</h1>
                                <p>
                                    Para <strong>não</strong> demorar anos produzindo o game, estamos aqui buscando recursos para contratar uma galera incrível e
                                    poder finalizar o projeto dentro dos próximos <strong>dois anos</strong>. Entretanto, esse prazo é totalmente dependente das metas. 
                                </p>
                                <p>
                                    Caso a gente <strong>não bata as metas</strong>, o projeto pode demorar mais tempo para ser lançado ou até mesmo reduzido
                                    para seu lançamento final. Mas não se preocupe, tudo será <strong>devidamente avisado com antecedência</strong>,
                                    de acordo com nossa proposta de desenvolvimento transparente.
                                </p>

                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Obrigado!</h1>
                                <p>
                                    Um <strong>muito obrigado</strong> a você que leu até aqui!
                                    Poder viver através do desenvolvimento de games é um sonho e sua ajuda é muito bem vinda!
                                    Caso ainda tenha alguma dúvida ou queira conversar comigo,
                                    mande um PM no <strong><a href="https://twitter.com/katreque" target="_blank" rel="noopener noreferrer">Twitter</a></strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {
                visibilidadeBotaoApoiar &&
                <div className="m-4" style={{position: "fixed", bottom: 0, right: 0}}>
                    <a className="btn btn-lg btn-danger" href="#apoiar" style={{boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)"}}>Apoiar</a>
                </div>
            }
            <Footer backgroundColor="#022029" />
        </>
    );
}

export default BoralaaLayoutBR;